import axios from 'axios'

const ENDPOINT = process.env.REACT_APP_SWODA_API_ENDPOINT || '/app/sg'

const createRequest = (path, method, options) => {
  const signal = axios.CancelToken.source()
  const request = async () =>
    axios.request({
      url: `${ENDPOINT}${path}`,
      method: method,
      cancelToken: signal.token,
      ...options,
    })
  return {
    request,
    cancel: (reason = 'API call cancelled.') => signal.cancel(reason),
  }
}

export default createRequest
