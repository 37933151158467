import { useContext, useMemo } from 'react'
import { SgContext } from '../providers/SgProvider'

export const useSgList = () => {
  const { sgState } = useContext(SgContext)
  const LIMIT_LIST = 99
  const { destinations } = sgState

  const utilizationRate = useMemo(() => {
    if (!sgState.destinations) return undefined
    return `${Math.round((sgState.destinations / LIMIT_LIST) * 100)} %`
  }, [sgState.destinations])

  const isOverLimit =
    !!sgState.destinations && sgState.destinations > LIMIT_LIST

  return {
    LIMIT_LIST,
    destinations,
    utilizationRate,
    isOverLimit,
  }
}

export type SgListType = ReturnType<typeof useSgList>
