/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { FormatListNumbered, Person, Image } from '@material-ui/icons'
import DashboardIcon from '@material-ui/icons/DashboardOutlined'
import ListIcon from '@material-ui/icons/List'
import ListAltIcon from '@material-ui/icons/ListAlt'
import SettingsIcon from '@material-ui/icons/SettingsOutlined'
import { useContext } from 'react'
import {
  TableListContext,
  TableItem,
} from '../../../../providers/TableListProviders'

export const useNavigationConfig = () => {
  const { tableList } = useContext(TableListContext)
  const menuItems =
    tableList.length > 0
      ? tableList
          .sort((a: TableItem, b: TableItem) => {
            if (a.order < b.order) {
              return -1
            }
            if (a.order > b.order) {
              return 1
            }
            if (a.order === b.order && a.id > b.id) {
              return -1
            }
            return 0
          })
          .map((c) => {
            return {
              title: c.tableName,
              href: `/tables/${c.id}`,
              icon: ListIcon,
            }
          })
      : tableList

  const navigations = [
    {
      // title: 'Pages',
      pages: [
        {
          title: 'ダッシュボード',
          href: '/dashboards/default',
          icon: DashboardIcon,
        },
        ...menuItems,
        {
          title: 'メール一覧',
          href: '/mails',
          icon: ListAltIcon,
        },
        {
          title: 'メールマガジン一覧',
          href: '/mailmagazines',
          icon: ListAltIcon,
        },
        {
          title: 'シナリオ一覧',
          href: '/scenarios',
          icon: FormatListNumbered,
        },
        {
          title: 'メール送信者一覧',
          href: '/senders',
          icon: Person,
        },
        {
          title: 'メディア一覧',
          href: '/uploads',
          icon: Image,
        },
        {
          title: '設定',
          icon: SettingsIcon,
          children: [
            {
              title: 'シート',
              href: '/settings/tables',
            },
          ],
        },
      ],
    },
  ]

  return {
    navigations,
  }
}

// export default [
//   {
//     // title: 'Pages',
//     pages: [
//       {
//         title: 'Overview',
//         href: '/overview',
//         icon: HomeIcon
//       },
//       {
//         title: '顧客管理',
//         href: '/customers',
//         icon: ListIcon,
//       },
//       {
//         title: '設定',
//         icon: SettingsIcon,
//         children: [
//           // {
//           //   title: 'Account',
//           //   href: '/settings/account'
//           // },
//           // {
//           //   title: 'Company',
//           //   href: '/settings/company'
//           // },
//           // {
//           //   title: 'Users',
//           //   href: '/settings/users'
//           // },
//           {
//             title: 'テーブル',
//             href: '/settings/tables'
//           },
//           // {
//           //   title: 'Subscription',
//           //   href: '/settings/subscription'
//           // },
//           // {
//           //   title: 'Notifications',
//           //   href: '/settings/notifications'
//           // },
//           // {
//           //   title: 'Security',
//           //   href: '/settings/security'
//           // }
//         ]
//       },
//       // {
//       //   title: 'Authentication',
//       //   href: '/auth',
//       //   icon: LockOpenIcon,
//       //   children: [
//       //     {
//       //       title: 'Login',
//       //       href: '/auth/login'
//       //     },
//       //     {
//       //       title: 'Register',
//       //       href: '/auth/register'
//       //     }
//       //   ]
//       // },
//       // {
//       //   title: 'Errors',
//       //   href: '/errors',
//       //   icon: ErrorIcon,
//       //   children: [
//       //     {
//       //       title: 'Error 401',
//       //       href: '/errors/error-401'
//       //     },
//       //     {
//       //       title: 'Error 404',
//       //       href: '/errors/error-404'
//       //     },
//       //     {
//       //       title: 'Error 500',
//       //       href: '/errors/error-500'
//       //     }
//       //   ]
//       // }
//     ]
//   },
//   // {
//   //   title: 'Support',
//   //   pages: [
//   //     {
//   //       title: 'Presentation',
//   //       href: '/presentation',
//   //       icon: PresentToAllIcon
//   //     },
//   //     {
//   //       title: 'Getting started',
//   //       href: '/getting-started',
//   //       icon: CodeIcon
//   //     },
//   //     {
//   //       title: 'Changelog',
//   //       href: '/changelog',
//   //       icon: ViewModuleIcon,
//   //       label: () => <Label color={colors.blue['500']}>v1.2.0</Label>
//   //     }
//   //   ]
//   // }
// ];
