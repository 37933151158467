import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'

const env = process.env
const firebaseConfig = {
  apiKey: env.REACT_APP_SWODA_API_KEY,
  authDomain: env.REACT_APP_SWODA_AUTH_DOMAIN,
  databaseURL: env.REACT_APP_SWODA_DATABASE_URL,
  projectId: env.REACT_APP_SWODA_PROJECT_ID,
  storageBucket: env.REACT_APP_SWODA_STORAGE_BUCKET,
  messagingSenderId: env.REACT_APP_SWODA_MESSAGING_SENDER_ID,
  appId: env.REACT_APP_SWODA_APP_ID,
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export const firestore = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()

export const provider = new firebase.auth.GoogleAuthProvider()
export const signInWithGoogle = () => auth.signInWithPopup(provider)
export const signOut = () => auth.signOut()
// no need for now.
// firestore.settings({ timestampsInSnapshots: true });

// attach firebase to window objects to debug, using dev tools etc....
window.firebase = firebase

export const createUserProfileDocument = async (user, additionalData) => {
  if (!user) {
    return
  }

  // DB上のUser profileが置かれるだろう場所へのReferenceを取得
  const userReference = firestore.doc(`users/${user.uid}`)

  // Locationにあるデータをゲットしてfetchする
  const snapshot = await userReference.get()

  // userProfileが存在するか
  if (!snapshot.exists) {
    const { email } = user
    const createdAt = new Date()
    try {
      await userReference.set({
        email,
        createdAt,
        ...additionalData,
      })
    } catch (error) {
      console.error('Error creating user:', error.message)
    }
  }

  return getUserProfileDocument(user.uid)
}

export const getUserProfileDocument = async (uid) => {
  if (!uid) return null
  try {
    return firestore.collection('users').doc(uid)
  } catch (error) {
    console.error('Error fetching user: ', error.message)
  }
}

export default firebase
