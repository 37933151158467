import React from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import MomentUtils from '@date-io/moment'
import { Provider as StoreProvider } from 'react-redux'
import UserProvider from './providers/UserProviders'
import { ThemeProvider } from '@material-ui/styles'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { renderRoutes } from 'react-router-config'

import theme from './theme'
import { configureStore } from './store'
import routes from './routes'
import moment from 'moment'
import 'moment/locale/ja'
import { ScrollReset, GoogleAnalytics } from './components'
import './mixins/chartjs'
import './mixins/moment'
import './mixins/validate'
import './mixins/prismjs'
import './mock'
import './assets/scss/index.scss'
import { SgProvider } from './providers/SgProvider'

const history = createBrowserHistory()
const store = configureStore()

class ExtendedUtils extends MomentUtils {
  getCalendarHeaderText(date) {
    return moment(date).format('YYYY[年] MMMM')
  }
  getDateTimePickerHeaderText(date) {
    return moment(date).format('MMMM Do')
  }
  getHourText(date) {
    return moment(date).format('HH')
  }
  getMinuteText(date) {
    return moment(date).format('mm')
  }
}

const App = () => {
  return (
    <UserProvider>
      <SgProvider>
        <StoreProvider store={store}>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider
              utils={ExtendedUtils}
              locale="ja"
              libInstance={moment}
            >
              <Router history={history}>
                <ScrollReset />
                <GoogleAnalytics />
                {renderRoutes(routes)}
              </Router>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </StoreProvider>
      </SgProvider>
    </UserProvider>
  )
}

export default App
