/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'
import AuthLayout from './layouts/Auth'
import DashboardLayout from './layouts/Dashboard'
import ErrorLayout from './layouts/Error'
import DashboardDefaultView from './views/DashboardDefault'

// import PresentationView from './views/Presentation';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboards/default" />,
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login')),
      },
      {
        component: () => lazy(() => import('views/Error404')),
      },
    ],
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      // {
      //   path: '/errors/error-401',
      //   exact: true,
      //   component: lazy(() => import('views/Error401')),
      // },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404')),
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500')),
      },
      {
        component: () => lazy(() => import('views/Error404')),
      },
    ],
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/tables/:id',
        component: lazy(() => import('views/Tables')),
      },
      {
        path: '/settings/account',
        exact: true,
        component: lazy(() => import('views/AccountSettings')),
      },
      {
        path: '/settings/company',
        exact: true,
        component: lazy(() => import('views/CompanySettings')),
      },
      {
        path: '/settings/users',
        exact: true,
        component: lazy(() => import('views/UserSettings')),
      },
      {
        path: '/settings/tables/:id',
        component: lazy(() => import('views/FieldSettings')),
      },
      {
        path: '/settings/tables',
        component: lazy(() => import('views/TableSettings')),
      },
      {
        path: '/mails/new',
        component: lazy(() => import('views/MailEdit')),
      },
      {
        path: '/mails',
        component: lazy(() => import('views/MailList')),
      },
      {
        path: '/uploads',
        exact: true,
        component: lazy(() => import('views/UpLoads')),
      },
      {
        path: '/mailmagazines',
        component: lazy(() => import('views/MailMagazines')),
      },
      {
        path: '/scenarios/:id',
        component: lazy(() => import('views/ScenarioDetail')),
      },
      {
        path: '/scenarios',
        component: lazy(() => import('views/ScenarioList')),
      },
      {
        path: '/senders',
        component: lazy(() => import('views/SenderList')),
      },
      {
        path: '/settings',
        exact: true,
        component: lazy(() => import('views/Settings')),
      },
      {
        path: '/settings/:tab',
        exact: true,
        component: lazy(() => import('views/Settings')),
      },
      {
        path: '/dashboards/default',
        exact: true,
        component: DashboardDefaultView,
      },
      // {
      //   path: '/social-feed',
      //   exact: true,
      //   component: lazy(() => import('views/SocialFeed'))
      // },
      // {
      //   path: '/getting-started',
      //   exact: true,
      //   component: lazy(() => import('views/GettingStarted'))
      // },
      {
        component: () => lazy(() => import('views/Error404')),
      },
    ],
  },
]

export default routes
