import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {},
  dates: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  startDateButton: {
    marginRight: theme.spacing(1),
  },
  endDateButton: {
    marginLeft: theme.spacing(1),
  },
  calendarTodayIcon: {
    marginRight: theme.spacing(1),
  },
}))

const Header = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography component="h2" gutterBottom variant="overline">
        Dashboard
      </Typography>
      <Typography component="h1" gutterBottom variant="h3">
        ダッシュボード（近日順次リリース予定）
      </Typography>
      <Typography variant="subtitle1">
        現在の表示は参考用サンプルとなります
      </Typography>
    </div>
  )
}

Header.propTypes = {
  className: PropTypes.string,
}

Header.defaultProps = {}

export default Header
