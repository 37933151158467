import { combineReducers } from 'redux'
import mailReducer from './mailReducer'
import sessionReducer from './sessionReducer'

const rootReducer = combineReducers({
  session: sessionReducer,
  mail: mailReducer,
})

export default rootReducer
