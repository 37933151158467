import React, { Fragment, useEffect, useContext, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/styles'
import { Drawer, Divider, Paper, Avatar, Typography } from '@material-ui/core'
import { Hidden } from '@material-ui/core'

import useRouter from 'utils/useRouter'
import { Navigation } from 'components'
import { useNavigationConfig } from './useNavigationConfig'
import { UserContext } from '../../../../providers/UserProviders'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflowY: 'auto',
  },
  content: {
    padding: theme.spacing(2),
  },
  profile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content',
  },
  avatar: {
    width: 60,
    height: 60,
  },
  name: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  navigation: {
    marginTop: theme.spacing(2),
  },
}))

const NavBar = (props) => {
  const { openMobile, onMobileClose, className, ...rest } = props

  const classes = useStyles()
  const router = useRouter()
  const session = useSelector((state) => state.session)
  const { user } = useContext(UserContext)
  const { navigations } = useNavigationConfig()

  useEffect(() => {
    if (openMobile) {
      onMobileClose && onMobileClose()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.location.pathname])

  if (
    (user === undefined && user.firstName === null) ||
    user.companyId === undefined
  ) {
    return null
  }

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          <div {...rest} className={clsx(classes.root, className)}>
            <NavBarContent user={user} classes={classes} />
          </div>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Paper
          {...rest}
          className={clsx(classes.root, className)}
          elevation={1}
          square
        >
          <NavBarContent user={user} classes={classes} />
        </Paper>
      </Hidden>
    </Fragment>
  )
}

const NavBarContent = (props) => {
  const { classes, user, session, list } = props
  const { navigations } = useNavigationConfig()
  if (navigations?.length === 0) return null

  return (
    <div className={classes.content}>
      <div className={classes.profile}>
        {/* <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        src={session.user.avatar}
        to="/profile/1/timeline"
      /> */}
        <Typography className={classes.name} variant="h4">
          {user.familyName} {user.firstName}
        </Typography>
        <Typography variant="body2">{user.position}</Typography>
      </div>
      <Divider className={classes.divider} />
      <nav className={classes.navigation}>
        {navigations.map((list, i) => (
          <Navigation
            component="div"
            key={`${list.title}-${i}`}
            pages={list.pages}
            title={list.title}
          />
        ))}
      </nav>
    </div>
  )
}

NavBar.propTypes = {
  className: PropTypes.string,
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
}

export default NavBar
