import {
  Avatar,
  Card,
  CircularProgress,
  colors,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { Help } from '@material-ui/icons'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'
import { Label } from 'components'
import PropTypes from 'prop-types'
import React from 'react'
import gradients from 'utils/gradients'
import { useSgList } from '../../../../hooks/useSgList'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  content: {
    flexGrow: 1,
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  progress: {
    margin: theme.spacing(0, 1),
    flexGrow: 1,
  },
  avatar: {
    backgroundImage: gradients.blue,
    height: 48,
    width: 48,
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  info: {
    display: 'flex',
    alignItems: 'center',
  },
}))

const SystemHealth = (props) => {
  const { className, ...rest } = props
  const classes = useStyles()
  const { destinations, utilizationRate, LIMIT_LIST } = useSgList()

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <div className={classes.content}>
        <Typography component="h3" gutterBottom variant="overline">
          マーケティングメール設定人数
        </Typography>
        <div className={classes.details}>
          {destinations >= 0 ? (
            <>
              <Typography variant="h3">{destinations}セット</Typography>
              <Label
                className={classes.label}
                color={colors.indigo[600]}
                variant="outlined"
              >
                {utilizationRate}
              </Label>
            </>
          ) : (
            <Typography variant="h3">
              <CircularProgress size={22} />
            </Typography>
          )}
        </div>
        <div className={classes.info}>
          <Typography component="h4" gutterBottom={false} variant="overline">
            {`上限${LIMIT_LIST}セット`}
          </Typography>
          <Tooltip title="シナリオセット人数+メルマガ使用数">
            <Help style={{ fontSize: '1rem' }} />
          </Tooltip>
        </div>
      </div>

      <Avatar className={classes.avatar}>
        <ContactMailIcon />
      </Avatar>
    </Card>
  )
}

SystemHealth.propTypes = {
  className: PropTypes.string,
}

export default SystemHealth
