import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Card, Typography, Avatar, colors } from '@material-ui/core'
import MailOutlineIcon from '@material-ui/icons/MailOutline'

import { Label } from 'components'
import gradients from 'utils/gradients'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '100%',
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  label: {
    marginLeft: theme.spacing(1),
  },
  avatar: {
    backgroundImage: gradients.blue,
    height: 48,
    width: 48,
  },
}))

const NewProjects = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()

  const data = {
    value: '1,560',
    countname: '通',
    difference: '-10%',
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <div>
        <Typography component="h3" gutterBottom variant="overline">
          今月のメール配信数
        </Typography>
        <div className={classes.details}>
          <Typography variant="h3">
            {data.value}
            {data.countname}
          </Typography>
          <Label
            className={classes.label}
            color={colors.red[600]}
            variant="outlined"
          >
            {data.difference}
          </Label>
        </div>
        <Typography component="h3" gutterBottom variant="overline">
          上限：30,000通/月
        </Typography>
      </div>
      <Avatar className={classes.avatar}>
        <MailOutlineIcon />
      </Avatar>
    </Card>
  )
}

NewProjects.propTypes = {
  className: PropTypes.string,
}

export default NewProjects
