import { AxiosResponse } from 'axios'
import React, {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { get } from '../services/SgList'
import { UserContext } from './UserProviders'

type Context = {
  sgState: SgState
  setSgState: Dispatch<React.SetStateAction<SgState>>
}

export type SgState = {
  destinations: number
}

type RequestRef = {
  request: () => Promise<AxiosResponse<any>>
  cancel: (reason?: string) => void
}

const initialState = {
  destinations: -1,
}

export const SgContext = createContext({} as Context)

export const SgProvider: React.VFC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [sgState, setSgState] = useState<SgState>(initialState)
  const { user } = useContext(UserContext)
  const requestRef = useRef<RequestRef | null>(null)

  useEffect(() => {
    if (!user) {
      return
    }

    const getSgList = async () => {
      requestRef.current = get(user)
      try {
        const {
          data: { data },
        } = await requestRef.current.request()
        setSgState((oldState) => ({
          ...oldState,
          destinations: Number(data),
        }))
        requestRef.current = null
      } catch (e) {
        console.error(e)
      }
    }
    getSgList()
    return () => {
      if (requestRef.current !== null) {
        requestRef.current.cancel()
      }
    }
  }, [user, sgState.destinations])

  return (
    <SgContext.Provider value={{ sgState, setSgState }}>
      {children}
    </SgContext.Provider>
  )
}
