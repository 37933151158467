import React, { createContext, useContext, useEffect, useState } from 'react'
import { collectIdsAndDocs } from 'utilities'
import { firestore } from '../firebase'
import { UserContext } from './UserProviders'

export type TableItem = {
  id: string
  tableName: string
  order: number
}

type Context = {
  tableList: TableItem[]
}

export const TableListContext = createContext<Context>({ tableList: [] })

type Props = {
  children: React.ReactNode
}

export const TableListProvider: React.VFC<Props> = ({ children }) => {
  const { user }: { user: any } = useContext(UserContext)
  const [tableList, setTableList] = useState<any[]>([])

  useEffect(() => {
    if (!user?.companyId) return
    const unsubscribeFromFirestore = firestore
      .collection('companies')
      .doc(user.companyId)
      .collection('tables')
      .onSnapshot((snapshot) => {
        const tables: TableItem[] = snapshot.docs.map(collectIdsAndDocs)
        setTableList(tables)
      })
    return () => unsubscribeFromFirestore()
  }, [user])

  return (
    <TableListContext.Provider value={{ tableList }}>
      {children}
    </TableListContext.Provider>
  )
}
