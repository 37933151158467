import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { makeStyles } from '@material-ui/styles'
import { Card, CardHeader, CardContent, Divider } from '@material-ui/core'

import { GenericMoreButton } from 'components'
import { Chart } from './components'

const useStyles = makeStyles((theme) => ({
  root: {},
  content: {},
  buttons: {
    display: 'flex',
    justifyContent: 'center',
    '& > *': {
      marginLeft: theme.spacing(1),
    },
  },
  inner: {
    height: 375,
    minWidth: 500,
  },
  chart: {
    height: '100%',
  },
}))

const PerformanceOverTime = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()

  const data = {
    thisWeek: {
      data: [],
      labels: [],
    },
    thisMonth: {
      data: [],
      labels: [],
    },
    pastDate: {
      data: [10, 5, 11, 20, 13, 28, 18, 4, 13, 12, 13, 5, 8, 9],
      labels: [
        '14日前',
        '13日前',
        '12日前',
        '11日前',
        '10日前',
        '9日前',
        '8日前',
        '7日前',
        '6日前',
        '5日前',
        '4日前',
        '3日前',
        '2日前',
        '1日前',
      ],
    },
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader
        action={<GenericMoreButton />}
        title="メールマーケティングの反応記録（到達・開封・リンククリックなど）"
      />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Chart
              className={classes.chart}
              data={data.pastDate.data}
              labels={data.pastDate.labels}
            />
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  )
}

PerformanceOverTime.propTypes = {
  className: PropTypes.string,
}

export default PerformanceOverTime
