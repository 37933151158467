import React, { Component, createContext } from 'react'
import { auth, createUserProfileDocument, firestore } from '../firebase'

export const UserContext = createContext({ user: null, check: false })

// function component (test)
// const UserProvider = () => {
//   const [user, setUser] = useState(null);
//   const [check, setCheck] = useState(false);
//   const unsubscribeFromAuth = useRef(null);
//   useEffect(() => {
//     unsubscribeFromAuth.current = auth.onAuthStateChanged(async (userAuth) => {
//       if (userAuth) {
//         const userRef = await createUserProfileDocument(userAuth);
//         userRef.onSnapshot((snapshot) => {
//           setUser({ uid: snapshot.id, ...snapshot.data() });
//           setCheck(true);
//         });
//       }
//       setUser(userAuth);
//       setCheck(true);
//     });
//     return () => {
//       unsubscribeFromAuth.current();
//     };
//   },[]);

//   return (
//     check && (
//       <UserContext.Provider value={{ user }}>
//         {children}
//       </UserContext.Provider>
//     )
//   );
// };

class UserProvider extends Component {
  state = { user: null, check: false }

  unsubscribeFromAuth = null

  componentDidMount = () => {
    this.unsubscribeFromAuth = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        const userRef = await createUserProfileDocument(userAuth)
        userRef.onSnapshot((snapshot) => {
          this.setState({ user: { uid: snapshot.id, ...snapshot.data() } })
        })
      }
      this.setState({ user: userAuth, check: true })
    })
  }

  componentWillUnmount = () => {
    this.unsubscribeFromAuth()
  }

  updateUser = (userId, userObject) => {
    auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        await firestore.collection('users').doc(userId).update(userObject)
      }
    })
  }

  actions = {
    updateUser: this.updateUser,
  }

  render() {
    const { user, check } = this.state
    const { children } = this.props
    return (
      check && (
        <UserContext.Provider value={{ user: user, ...this.actions }}>
          {children}
        </UserContext.Provider>
      )
    )
  }
}

export default UserProvider
