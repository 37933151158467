import { firestore } from './firebase'
import { storage } from './firebase'

export const collectIdsAndDocs = (doc) => {
  return {
    id: doc.id,
    ...doc.data(),
  }
}

// firestore fetcher
export const collectionFetcher = (path) =>
  firestore
    .collection(path)
    .get()
    .then((collectionSnapshot) =>
      collectionSnapshot.docs.map(collectIdsAndDocs)
    )
export const documentFetcher = (path) =>
  firestore
    .doc(path)
    .get()
    .then((documentSnapshot) => documentSnapshot.data())

/**
 * 文字列がURLかどうかを返す
 * @param str
 * @returns {boolean}
 */
export const isValidURL = (str) => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ) // fragment locator
  return !!pattern.test(str)
}

/**
 * 一定時間処理を待たせる
 * @param waitSeconds 秒
 * @param someFunction
 * @returns {Promise<unknown>}
 */
export const sleep = (waitSeconds) =>
  new Promise((resolve) => setTimeout(resolve, waitSeconds * 1000))

/**
 * Firebase Storageにファイルをアップロード
 * @param {File} file
 * @param {string} uploadPath
 * @returns {string} downloadURL
 */
export const uploadFile = async (file, uploadPath) => {
  const storageRef = storage.ref(uploadPath)
  const snapshot = await storageRef.put(file)
  return await snapshot.ref.getDownloadURL()
}
