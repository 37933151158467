import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'

import { UserContext } from '../providers/UserProviders'

const Authentication = ({ loading, children }) => {
  const { user } = useContext(UserContext)
  if (loading) return null

  return (
    <div>
      {user ? (
        <React.Fragment>{children}</React.Fragment>
      ) : (
        <Redirect to="/auth/login" />
      )}
    </div>
  )
}

export default Authentication
