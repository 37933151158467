import createRequest from 'utils/api'

export const get = (user: any) => {
  const { uid, gid } = user
  return createRequest('/jido/sg_lists/get', 'post', {
    data: {
      uid,
      gid,
    },
  })
}
